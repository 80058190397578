export const printInfoMixin = {
  methods: {
    getPrintInfo (paramJson) {
      let printInfo = {
        template: '',
        templateType: 1,
        title: 0
      }

      if (paramJson && paramJson.length > 0) {
        for (let i = 0; i < paramJson.length; i++) {
          const item = paramJson[i];
          switch (item.ParamCode) {
            case "PrintTemplate":
              printInfo.template = item.ParamValue;
              break;
            case "PrintTitle":
              printInfo.title = (item.ParamValue && parseInt(item.ParamValue)) || 0;
              break;
            case "PrintTemplateType":
              printInfo.templateType = (item.ParamValue && parseInt(item.ParamValue)) || 1;
              break;
            default:
              break;
          }
        }
      }

      return printInfo;
    },
    // 获取打印模板
    getPrintTemplate(paramJson) {
      if (paramJson && paramJson.length > 0) {
        let obj = _.find(paramJson, a=> a.ParamCode == "PrintTemplate");
        return (obj && obj.ParamValue) || "";
      }

      return "";
    },
    // 获取打印类型
    getPrintTemplateType(paramJson) {
      if (paramJson && paramJson.length > 0) {
        let obj = _.find(paramJson, a=> a.ParamCode == "PrintTemplateType");
        return (obj && parseInt(obj.ParamValue)) || 1;
      }

      return 1;
    },

    // 获取抬头
    getPrintTitle(paramJson) {
      if (paramJson && paramJson.length > 0) {
        let obj = _.find(paramJson, a => a.ParamCode == "PrintTitle");
        console.log(obj, 'get title');
        return (obj && parseInt(obj.ParamValue)) || 0;
      }

      return 0;
    }
  }
}
